
import Address from '@/components/Address.vue'
import Pic from '@/components/Pic.vue'
import Nullable from '@/components/Nullable.vue'
import Holder from '@/provider/Holder.vue'
import TheRankNumber from '@/modules/weTradeCup/components/TheRankNumber.vue'
import { readMyPrize, TGiftItem } from '@/modules/weTradeCup/weTradeCup.api'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import { defineComponent, shallowRef } from 'vue'
import { translate } from '@/i18n'
import { cupDateFormat } from '@/modules/weTradeCup/weTradeCup'

export default defineComponent({
  name: 'MyPrize',
  components: {
    TheRankNumber,
    Address,
    Pic,
    PageWithHeader,
    Nullable,
    Holder,
  },
  props: {},
  setup () {
    const giftList = shallowRef<TGiftItem[]>([])
    readMyPrize().then(res => {
      giftList.value = res
    })

    return {
      giftList,
      translate,
      cupDateFormat,
    }
  },
})
