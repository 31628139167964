/**
* @author 阿佑[ayooooo@petalmail.com]
* @date 2022/4/28 17:24
* @description
*   Address.vue of WeTrade
*/
<template>
<RouterLink v-if="isEmpty" to="/me/setting/address">
  <slot />
</RouterLink>
<div v-else class="br-sm no-of">
  <div class="light"></div>
  <div class="p-16">
    <div class="d-f">
      <t as="p" path="cupmyprizes_3" class="flex-1 f-bold f-nm">Deliver to:</t>
      <RouterLink to="/me/setting/address">
        <button class="btn xs round f-sm px-16">
          <Pic src="modify_grey" width="16" height="16" />
          <t path="cupmyprizes_4">Edit</t>
        </button>
      </RouterLink>
    </div>
    <p>{{prop('recipientAddress', address)}} {{prop('recipientCity', address)}} {{prop('recipientProvince', address)}}</p>
    <p>{{prop('recipientName', address)}}</p>
  </div>
</div>
</template>

<script lang="ts">
import Pic from '@/components/Pic.vue'
import { Address, readAddress } from '@/pages/setting/setting.api'
import { computed, defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'Address',
  components: { Pic },
  props: {},
  setup () {
    const address = shallowRef<Address | null>(null)
    const isEmpty = computed(() => !address.value?.recipientAddress)

    readAddress().then(resp => address.value = resp)

    return {
      isEmpty,
      address,
    }
  },
})
</script>

<style scoped>
.light {
  height: 4px;
  background: linear-gradient(45deg, #f25953 12.5%, white 12.5%, white 25%, #5590d6 25%, #5590d6 37.5%, white 37.5%, white 50%, #f25953 50%, #f25953 62.5%, white 62.5%, white 75%, #5590d6 75%, #5590d6 87.5%, white 87.5%, white 100%);
  background-size: 70px 70px;
}
</style>
