/**
* @author 阿佑[ayooooo@petalmail.com]
* @date 2022/4/27 10:54
* @description
*   MyPrize.vue of WeTrade
*/
<template>
<PageWithHeader>
  <template #title><t path="cup_3">My Prize</t></template>

  <template #body>
    <div class="my-wt-cup scroll-y flex-1 pb-32">
      <Pic src="cup/wt/big_cup" style="width: 100%"/>
      <div class="px-16">
        <Address>
          <button class="btn primary block br-sm f-bold">
            <t path="cupmyprizes_2">Add the delivery address</t>
          </button>
        </Address>

        <Holder :content="giftList">
          <Nullable :content="giftList">
            <div class="list mt-32 px-16 pb-16 br-lg" v-for="(item, index) in giftList" :key="index" style="background: #432CAA">
              <div class="t-center">
                <span class="d-ib f-32 f-bold c-white title">
                  {{ ['--', translate('cupmyprizes_5'), translate('cupmyprizes_6'), translate('cupmyprizes_7')][item.type] }}
                </span>
              </div>
              <div class="mt-24">
                <div class="df-middle c-white">
                  <span class="shadow df-middle">
                    <TheRankNumber :rank="item.rank" by-desc sm class="mr-4" />{{item.rank}}
                  </span>
                  <div class="flex-1 ml-8">
                    {{cupDateFormat(item.startDate)}} - {{cupDateFormat(item.endDate)}}
                  </div>
                  <p class="f-xxl f-bold" :class="item.rate>0?'c-success':'c-danger'">{{ item.rate/100 }}%</p>
                </div>
                <div class="br-lg px-16 py-12 mt-8 d-f bg-white">
                  <Pic src="cup/wt/gold_heap" width="16" height="16" />
                  <t path="cupmyprizes_8" class="ml-8 c-black">Gold</t>
                  <div class="flex-1 f-bold ml-16">{{ item.actualItemNumber }}g</div>
                  <t as="span"
                    :class="item.actualItemStatus===0?'c-primary':'c-tip'"
                    :path="item.actualItemStatus===0?translate('cupmyprizes_10'):translate('cupmyprizes_11')">
                  </t>
                </div>
                <div class="br-lg px-16 py-12 mt-8 d-f bg-white">
                  <Pic src="cup/wt/gold_heap" width="16" height="16" />
                  <t path="cupmyprizes_9" class="ml-8 c-black">Credit</t>
                  <div class="flex-1 f-bold ml-16"><money :v="item.prizeAmount"></money></div>
                  <t as="span"
                    :class="item.prizeStatus===0?'c-primary':'c-tip'"
                    :path="item.prizeStatus===0?translate('cupmyprizes_10'):translate('cupmyprizes_12')">
                  </t>
                </div>
              </div>
            </div>
          </Nullable>
        </Holder>
      </div>
    </div>
  </template>
</PageWithHeader>
</template>

<script lang="ts">
import Address from '@/components/Address.vue'
import Pic from '@/components/Pic.vue'
import Nullable from '@/components/Nullable.vue'
import Holder from '@/provider/Holder.vue'
import TheRankNumber from '@/modules/weTradeCup/components/TheRankNumber.vue'
import { readMyPrize, TGiftItem } from '@/modules/weTradeCup/weTradeCup.api'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import { defineComponent, shallowRef } from 'vue'
import { translate } from '@/i18n'
import { cupDateFormat } from '@/modules/weTradeCup/weTradeCup'

export default defineComponent({
  name: 'MyPrize',
  components: {
    TheRankNumber,
    Address,
    Pic,
    PageWithHeader,
    Nullable,
    Holder,
  },
  props: {},
  setup () {
    const giftList = shallowRef<TGiftItem[]>([])
    readMyPrize().then(res => {
      giftList.value = res
    })

    return {
      giftList,
      translate,
      cupDateFormat,
    }
  },
})
</script>

<style scoped>
.my-wt-cup {
  background: #230E8A;
}

.title {
  width: 183px;
  line-height: 48px;
  border-radius: 0 0 16px 16px;
  background: #341B9D;
}

.shadow {
  padding: 1px 5px;
  border-radius: 10px;
  background: #230E8A;
}
</style>
